@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .hoverEffect {
    @apply duration-300 cursor-pointer;
  }
  .headerSedenavLi {
    @apply hover:underline underline-offset-2 cursor-pointer text-gray-300 hover:text-white;
  }
}

/* ============= NavLink active Start here ============== */
nav .active li {
  color: black;
  font-weight: 600;
  text-decoration: 1px underline black;
}

@media screen and (max-width: 665px) {
  nav .active li {
    color: white;
    font-weight: 600;
    text-decoration: 1px underline white;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}



.kebab-main {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* .kebab-icon span {
  display: block;
  cursor: pointer;
  transition: all 300ms;
} */
.kebab-icon span {
  display: flex;
  cursor: pointer;
  transition: all 300ms;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: linear-gradient(rgb(19, 113, 239) 0%, rgb(51, 226, 199) 100%);
  border-radius: 4px;
}
.kebab-icon span svg {
  display: block;
}

/* .kebab-icon span:hover svg path {
fill: #1a8be5;
} */

.kebab-dropdown {
  position: absolute;
 top: 100%;
  right: 0px;
  z-index: 9;
}

.kebab-dropdown ul {
  width: 120px;
    background: #fff;
    box-shadow: 0 2px 4px #0000001a;
    overflow: hidden;
    border-radius: 6px;
    padding: 5px 0;
    border: 1px solid #e5e5e5;
}

.kebab-dropdown ul li a {
  padding: 8px 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  text-align: left;
  color: #2d2d2d;
  display: flex;
  transition: all 300ms;
  align-items: center;
  gap: 5px;
}

.kebab-dropdown ul li a em {
  display: block;
  font-style: normal;
}

.kebab-dropdown ul li a em svg {
  display: block;
}

.kebab-dropdown ul li a em svg path {
  fill: #5E6670;
}

.kebab-dropdown ul li:first-child a em svg path {
  stroke: #5E6670;
  fill: unset;
}
.kebab-dropdown ul li a:hover {
  color: #1a8be5;
  background: rgb(33 85 205 / 10%);
}

.kebab-dropdown ul li a:hover em svg path{
  fill: #1a8be5;
}
.kebab-dropdown ul li:first-child a:hover em svg path{
  stroke: #1a8be5;
  fill: unset;
}
.kebab-flex {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  align-items: center;
}

.kebab-dropdown ul li .kebab-flex a{
  padding: 0;
}

.kebab-dropdown ul li+li {
  border-top: 1px solid #e7e4e4;
}
.viewdtl-btn {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  color: #3a9be7;
  cursor: pointer;
  transition: all 300ms;
  display: flex;
  align-items: center;
  gap: 2px;
}
.viewdtl-btn:hover {
  color: #2bc6d1;
}
.viewdtl-btn em {
  display: block;
  font-style: normal;
}

.viewdtl-btn em svg {
  display: block;
}
.viewdtl-btn:hover em svg path{
  fill: #2bc6d1;
}





.main-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  padding: 40px 20px;
  transition: all 0.3s;
}

.main-popup .overlay {
  position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #bcbcbc47;
    z-index: 0;
    backdrop-filter: blur(4px);
}

.lm-outer {
  width: 100%;
  height: 100%;
  display: table;
  vertical-align: middle;
  overflow: auto;
}

.lm-inner {
  display: table-cell;
  vertical-align: middle;
}

.popup-inner {
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  z-index: 5;
  position: relative;
  box-shadow: 0 16px 26px #27272721;
  max-height: calc(100vh - 80px);
  overflow: auto;
  animation: bounce-open 0.3s;
}

.popup-header {
  padding: 10px 15px;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  padding-right: 40px;
}

.popup-heading h3 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
}

.popup-title {
  display: flex;
  align-items: center;
  gap: 10px;
}
.popup-title span, .popup-title span svg {
  display: block;
}
.close-pop-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.close-pop-icon span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.close-pop-icon span:hover svg path {
  fill: red;
}
.popup-body {
  padding: 20px 15px;
}

.popup-btn {
  margin-top: 20px;
}
.circle-modal-icon {
  margin-bottom: 15px;
}











.detail-data {
  position: relative;
  /* padding: 6px 0; */
}

.detail-data:before, .detail-data:after {
  content: "";
  display: table;
  clear: both;
}
.detail-data p {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0;
  line-height: 1.4;
  width: 50%;
  float: left;
  /* height: 25px; */
}
.detail-data h4 {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.4;
  font-weight: 400;
    color: #3f3f3f;
  margin-left: 50%;
  position: relative;
  padding-left: 25px;
  display: flex;
  gap: 15px;
  align-items: center;
  /* height: 25px; */
}
.detail-data h4:before {
  content: ":";
  position: absolute;
  top: 0;
  left: 0;
  color: #000;
  padding: 0 5px;
}

.detail-data+.detail-data  {
margin-top: 10px;
}
.detail-data+.more-list {
  margin-top: 10px;
}

.more-list ul li+li{
  margin-top: 10px;
}



.slick-slider  .slick-prev, .slick-slider  .slick-next {
  display: flex;
  cursor: pointer;
  transition: all 300ms;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: linear-gradient(rgb(19, 113, 239) 0%, rgb(51, 226, 199) 100%);
  border-radius: 50%;
  z-index: 9;
}
.image-modal .popup-body {
  padding: 10px 5px;
}
.image-modal .popup-inner { width: 300px;}
.slick-slider .slick-prev {
  left: 5px;
}
.slick-slider .slick-next {
  right: 5px;
}
.slick-slider .slick-dots {bottom: -5px;}
.main-popup.image-modal { padding: 5px;}

.slick-next:before, .slick-prev:before{
display: none;
}

.slick-slider .slick-prev:after, .slick-slider .slick-next:after{
  content: "";
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background-size: 13px 13px;
  background-repeat: no-repeat;
  background-position: center center;
  width: 13px;
  height: 13px;
}
.slick-slider .slick-prev:after{
  background-image:  url('data:image/svg+xml, <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 20L0 10L10 0L11.775 1.775L3.55 10L11.775 18.225L10 20Z" fill="white"/></svg>');
}
.slick-slider .slick-next:after{
  background-image: url('data:image/svg+xml, <svg width="12" height="11" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.775 20L0 18.225L8.225 10L0 1.775L1.775 0L11.775 10L1.775 20Z" fill="%23ffffff" /></svg>');
}

.slick-slider .slick-dots li button {
  width: 8px;
  height: 8px;
  padding: 0;
  margin: 5px 4px;
}


.slick-slider .slick-dots li button:before {
  font-size: 0;
  width: 8px;
  height: 8px;
  border: 1px solid #1a76d1;
  margin: 5px 4px;
  background: #fff;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity .2s ease;
  border-radius: 50%;
  background-color: #fff;
}

.slick-slider .slick-dots li.slick-active button:before {
  opacity: 1;
  color: black;
  background-color: #1a76d1;
  border-color: #1a76d1;
}
.slick-slider .slick-slide img {
  display: block;
  height: 290px;
  aspect-ratio: 3 / 2;
  object-fit: contain;
}
/* ============= NavLink active End here ================ */
